
import { Options, Vue } from 'vue-class-component';

import * as yup from 'yup';

import requests from '@/requests';

@Options({})
export default class ResetPassword extends Vue {
  public password = {
    new: '',
    confirm: '',
  }

  public loader = false;

  public formErrors: { [key: string]: string[] } = {
    new: [],
    confirm: [],
  }

  private token!: string;

  // eslint-disable-next-line
  private regexp = /^.*[!()\[\]{}|\\`~!@#$%^&*_\-+=;:'\",<>.\/?].*$/;

  // eslint-disable-next-line
  private regexpSpecialSymbol = `()[]{}|\\`+ '`' +`~!@#$%^&*_-+=;:'\",<>./?`;

  private schema = yup.object().shape({
    new: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/^.*(\w)(\D).*$/, 'This password is entirely numeric')
      .matches(this.regexp, `The password must contain at least 1 symbol: ${this.regexpSpecialSymbol}`)
      .nullable(),
    confirm: yup.string().oneOf([yup.ref('new'), null], 'Passwords must match'),
  });

  public send(): void {
    this.loader = true;

    this.resetFormsError();

    this.schema.validate(this.password, { abortEarly: false })
      .then(() => {
        this.resetPassword();
      })
      .catch((err: yup.ValidationError) => {
        this.loader = false;

        err.inner.forEach((e) => {
          if (e.path && this.formErrors[e.path]) {
            this.formErrors[e.path as string].push(e.message);
          }
        });
      });
  }

  private resetPassword() {
    // TODO: add yup and error;

    requests.profile.confirmResetPassword({ password: this.password.new, token: this.token })
      .then(() => { this.$router.push('/'); })
      .catch((err) => { console.log(err); })
      .finally(() => { this.loader = false; });
  }

  private resetFormsError(): void {
    this.formErrors = { new: [], confirm: [] };
  }

  created(): void {
    this.token = this.$route?.query?.token as string;

    if (this.token) {
      requests.profile.validationResetPasswordToken(this.token).catch(() => {
        this.$router.push('/');
      });
    } else {
      this.$router.push('/');
    }
  }
}
